@use "sass:meta" as ---dodp34hpast;@mixin input-label-styles {
  :global(.input-label) {
    @content
  }
}

@mixin input-error-styles {
  :global(.input-error) {
    @content
  }
}

@mixin input-field-styles {
  :global(.input-field) {
    @content
  }
}

@mixin input-placeholder-styles {
  &::-webkit-input-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &::-ms-input-placeholder {
    @content
  }
}

;@include ---dodp34hpast.load-css("sass-embedded-legacy-load-done:82592");