@use "sass:meta" as ---dodp34hpast;%covers-parent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

%vertical-flex {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

%visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

%flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-justify-centered {
  display: flex;
  align-items: center;
}

%flex-align-centered {
  display: flex;
  align-items: center;
}

%absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

;@include ---dodp34hpast.load-css("sass-embedded-legacy-load-done:77768");